import React, { useContext, useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Menu,
    MenuItem,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "./ThemeContext";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import MenuIcon from "@mui/icons-material/Menu";
import "../App.css";

function Header() {
    const navigate = useNavigate();
    const { toggleTheme } = useContext(ThemeContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (path) => {
        navigate(path);
        handleClose();
    };

    const menuItems = [
        { label: "Home", path: "/" },
        { label: "Create Assignment", path: "/select" },
        { label: "Shared Assessments", path: "/assessment/listall" },
        { label: "Features", path: "/features" },
        { label: "Feedback", path: "/feedback" },
    ];

    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={() => navigate("/")}
                >
                    <img
                        src="/logo.png"
                        alt="logo"
                        style={{ height: "40px" }}
                    />
                </IconButton>
                <Typography
                    variant="h6"
                    style={{ flexGrow: 1 }}
                    onClick={() => navigate("/")}
                >
                    Assessment Partner - <i className="betatag">beta</i>
                </Typography>
                {isMobile ? (
                    <>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleMenu}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {menuItems.map((item) => (
                                <MenuItem
                                    key={item.label}
                                    onClick={() =>
                                        handleMenuItemClick(item.path)
                                    }
                                >
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                ) : (
                    <>
                        {menuItems.map((item) => (
                            <Button
                                key={item.label}
                                color="inherit"
                                onClick={() => navigate(item.path)}
                                sx={{ mr: 1 }}
                            >
                                {item.label}
                            </Button>
                        ))}
                    </>
                )}
                <IconButton onClick={toggleTheme} color="inherit">
                    <Brightness4Icon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
