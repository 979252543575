import React, { useState, useEffect, useRef } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import "../App.css";
import config from "../config";
import { marked } from 'marked';

function MainForm() {
  const [customPrompts, setCustomPrompts] = useState([]);
  const [userLevels, setUserLevels] = useState([]);
  const [mainFields, setMainFields] = useState([]);
  const [assignmentGroups, setAssignmentGroups] = useState({});
  const [TypeOfAssessments, setTypeOfAssessments] = useState([]);
  const [taxonomyModels, setTaxonomyModels] = useState({});
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ws, setWs] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState("");
  const [selectedTaxonomyModel, setSelectedTaxonomyModel] = useState("");
  const [selectedTypeOfAssessment, setSelectedTypeOfAssessment] = useState("");
  const [selectedCustomPrompt, setSelectedCustomPrompt] = useState("");
  const [selectedUserLevel, setSelectedUserLevel] = useState("");
  const [selectedMainField, setSelectedMainField] = useState("");
  const [skillField, setSkillField] = useState("");
  const [TopicField, setTopicField] = useState("");
  const [genAiUse, setGenAiUse] = useState("none");
  const [gptVersion, setGptVersion] = useState("gpt-4-1106-preview");
  const [resultContents, setResultContents] = useState("");
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [token, setToken] = useState(null);
  const [showPrintButton, setShowPrintButton] = useState(false);

  const [assessmentFormats, setAssessmentFormats] = useState({});
  const [selectedAssessmentFormat, setSelectedAssessmentFormat] = useState("");

  const resultContentsRef = useRef(null);

  const handleWebSocketMessage = (event) => {
    const data = JSON.parse(event.data);
    if (data.status === "processing") {
      // setMessages((prevMessages) => [...prevMessages, data.message]);
      setMessages([data.message]);
    } else if (data.state === "Success") {
      setResultContents(data.content);
      setLoading(false);
      setToken(data.token);
    } else if (data.state === "done") {
      setShowSaveButton(true);
      setShowPrintButton(true);
    }
    if (data.error) {
      console.error("Error:", data.error);
    }
  };

  useEffect(() => {
    fetchData();
    const initWebSocket = () => {
      const ws = new WebSocket(`${config.appUrl}/ws`);
      ws.onopen = () => {
        setIsConnected(true);
      };
      ws.onmessage = handleWebSocketMessage;
      ws.onclose = () => {
        setIsConnected(false);
        setTimeout(initWebSocket, 1000); // Retry connection after 1 second
      };
      setWs(ws);
    };

    initWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  useEffect(() => {
    if (resultContentsRef.current) {
      const { bottom } = resultContentsRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (bottom > windowHeight) {
        const scrollTarget = bottom;
        window.scrollTo({
          top: scrollTarget,
          behavior: "smooth",
        });
      }
    }
  }, [resultContents]);

  const fetchData = () => {
    fetch(`${config.webUrl}/api/data`)
      .then((response) => response.json())
      .then((data) => {
        // console.log("Data fetched:", data);
        setUserLevels(data.user_level);
        setMainFields(data.main_field);
        setTypeOfAssessments(data.type_of_assessment);
        setAssessmentFormats({
          Written: data.assessment_formats.Written,
          "Quiz": data.assessment_formats.Quiz,
          Oral: data.assessment_formats.Oral,
          Practical: data.assessment_formats.Practical,
          Performance: data.assessment_formats.Performance,
          "Problem based": data.assessment_formats.Problem_based,
        });
        setAssignmentGroups({
          "Custom Prompts": data.assignments.CustomPrompts,
          Assignments: data.assignments.Assignments,
          "Lessons And Assessments": data.assignments.LessonsAndAssessments,
          "Learning Styles": data.assignments.LearningStyles,
        });
        setTaxonomyModels({
          "Blooms Taxonomy": data.taxonomies.blooms_taxonomy,
          "Solo Taxonomy": data.taxonomies.solo_taxonomy,
          "Creativity Taxonomy": data.taxonomies.creativity_taxonomy,
        });
        setCustomPrompts(data.custom_prompts);
      })
      .catch((error) => {
        console.error("Error fetching data:", error.message);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      assignmentType: selectedAssignment,
      customPrompt: selectedCustomPrompt,
      assessmentFormat: selectedAssessmentFormat,
      userLevel: selectedUserLevel,
      mainField: selectedMainField,
      TypeOfAssessment: selectedTypeOfAssessment,
      taxonomyModel: selectedTaxonomyModel,
      skillField: skillField,
      TopicField: TopicField,
      genAiUse: genAiUse,
      gptVersion: gptVersion,
      formType: "advanced",
    };

    console.log("Form data:", formData);

    if (ws && isConnected) {
      ws.send(JSON.stringify(formData));
      setLoading(true);
    }
  };

  const handleSaveAndShare = () => {
    setSaveButtonDisabled(true);

    fetch(`${config.webUrl}/assessments/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Assignment saved:", data);
      })
      .catch((error) => {
        console.error("Error saving assignment:", error);
      });
  };

  const handlePrint = () => {
    // Create an iframe element
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    
    // Style the iframe to be hidden
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = '0';
    
    // Get the iframe document
    const printDocument = iframe.contentDocument || iframe.contentWindow.document;
    printDocument.open();
    
    // Write the content to the iframe document
    printDocument.write(`
      <html>
        <head>
          <title>Assessment-Partner Generated - @McMaster</title>
        </head>
        <body>
          ${marked(resultContents)}
        </body>
      </html>
    `);
    printDocument.close();
    
    // Print the iframe content
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    
    // Remove the iframe after printing
    document.body.removeChild(iframe);
  };

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
      },
    },
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <form onSubmit={handleSubmit}>
            {/* Assessment format */}
            <Tooltip
              title="Select the format of the assessment eg. written, oral, practical etc..."
              placement="right"
            >
              <FormControl fullWidth margin="normal">
                <InputLabel id="assessment-format-label">
                  Select Assessment Format
                </InputLabel>
                <Select
                  labelId="assessment-format-label"
                  id="assessment_format"
                  MenuProps={menuProps}
                  value={selectedAssessmentFormat}
                  label="Select Assessment Format"
                  onChange={(e) => setSelectedAssessmentFormat(e.target.value)}
                >
                  {Object.entries(assessmentFormats).map(([group, items]) => [
                    <MenuItem
                      value={group}
                      key={group}
                      disabled
                      style={{ fontWeight: "bold" }}
                    >
                      {group}
                    </MenuItem>,
                    ...items.map((item) => (
                      <MenuItem
                        value={item.optionValue}
                        key={item.optionValue}
                        style={{
                          paddingLeft: "20px",
                        }}
                      >
                        {item.optionText}
                      </MenuItem>
                    )),
                  ])}
                </Select>
              </FormControl>
            </Tooltip>

            {/* Select Main Field */}
            <FormControl fullWidth margin="normal">
              <Tooltip
                title="Select a Discipline. This field is searchable."
                placement="right"
              >
                <Autocomplete
                  id="mainFields"
                  options={mainFields}
                  getOptionLabel={(option) => option.optionText}
                  renderInput={(params) => (
                    <TextField {...params} label="Select a Discipline" />
                  )}
                  value={
                    mainFields.find((option) => option.optionValue === selectedMainField) || null
                  }
                  onChange={(event, newValue) => {
                    setSelectedMainField(newValue ? newValue.optionValue : "");
                  }}
                />
              </Tooltip>
            </FormControl>

            {/* Select User Level */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="user-level-label">Level of study</InputLabel>
              <Select
                labelId="user-level-label"
                id="userLevels"
                value={selectedUserLevel}
                label="Select User Level"
                onChange={(e) => setSelectedUserLevel(e.target.value)}
              >
                {userLevels.map((item) => (
                  <MenuItem value={item.optionValue} key={item.optionValue}>
                    {item.optionText}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* TopicField */}
            {selectedMainField !== "other" &&
              selectedMainField !== "none" &&
              selectedMainField !== "" && (
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="Topic_field"
                    label="Topic within the discipline (optional)"
                    variant="outlined"
                    value={TopicField}
                    placeholder="e.g. Algebra, Geometry, etc."
                    onChange={(e) => setTopicField(e.target.value)}
                  />
                </FormControl>
              )}

            {/* Conditionally Rendered skills */}
            {selectedMainField !== "other" &&
              selectedMainField !== "none" &&
              selectedMainField !== "" && (
                <FormControl fullWidth margin="normal">
                  <Tooltip
                    title="Enter specific skills, competencies, or specializations related to the assessment (optional)"
                    placement="right"
                  >
                    <TextField
                      id="skills_field"
                      label="Skills | Competencies | Specialization (optional)"
                      variant="outlined"
                      value={skillField}
                      placeholder="e.g. Data Analysis, Critical Thinking, etc."
                      onChange={(e) => setSkillField(e.target.value)}
                    />
                  </Tooltip>
                </FormControl>
              )}

            {/* Type of Assessment - Generative/Summative */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="TypeOfAssessment-label">
                Select a Type of Assessment
              </InputLabel>
              <Tooltip
                title={
                  <React.Fragment>
                    - Formative assessment is an ongoing process that evaluates
                    student learning and understanding during the instructional
                    process, aiming to provide feedback for improvement.
                    <br />- Summative assessment occurs at the end of an
                    instructional unit to measure student learning against a
                    standard or benchmark, typically contributing to final
                    grades.
                  </React.Fragment>
                }
                placement="right"
              >
                <Select
                  labelId="TypeOfAssessment-label"
                  id="TypeOfAssessment"
                  value={selectedTypeOfAssessment}
                  label="Select Formative or Summative"
                  onChange={(e) => setSelectedTypeOfAssessment(e.target.value)}
                >
                  {TypeOfAssessments.map((item) => (
                    <MenuItem value={item.optionValue} key={item.optionValue}>
                      {item.optionText}
                    </MenuItem>
                  ))}
                </Select>
              </Tooltip>
            </FormControl>

            {/* Learning Taxonomy or Model Dropdown */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="taxonomy-model-label">
                Learning Taxonomy or Model
              </InputLabel>
              <Tooltip
                title="Educational taxonomy's are frameworks used to classify learning objectives and outcomes across different levels of complexity, from basic knowledge acquisition to advanced critical thinking skills. It serves as a guide for educators to design curriculum, assessments, and teaching strategies that progressively enhance students' learning and cognitive development."
                placement="right"
              >
                <Select
                  labelId="taxonomy-model-label"
                  id="taxonomyModels"
                  value={selectedTaxonomyModel}
                  label="Learning Taxonomy or Model"
                  onChange={(e) => setSelectedTaxonomyModel(e.target.value)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // Adjust ITEM_HEIGHT to change the height of each item and 4.5 is the number of items visible at a time
                        // width: 250, // Adjust the width as needed
                      },
                    },
                  }}
                >
                  {Object.entries(taxonomyModels).map(([group, items]) => [
                    <MenuItem
                      value={group}
                      key={group}
                      disabled
                      style={{ fontWeight: "bold" }}
                    >
                      {group}
                    </MenuItem>,
                    ...items.map((item) => (
                      <MenuItem
                        value={item.optionValue}
                        key={item.optionValue}
                        style={{
                          paddingLeft: "20px",
                        }}
                      >
                        {item.optionText}
                      </MenuItem>
                    )),
                  ])}
                </Select>
              </Tooltip>
            </FormControl>

            {/* Generative AI Usage */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="genai-use-label">Generative AI Usage</InputLabel>
              <Tooltip
                title="Select whether to incorporate generative AI in the assessment"
                placement="right"
              >
                <Select
                  labelId="genai-use-label"
                  id="genai_use"
                  value={genAiUse}
                  label="Generative AI Usage"
                  onChange={(e) => setGenAiUse(e.target.value)}
                >
                  <MenuItem value="none">None</MenuItem>
                  <MenuItem value="usegenai">
                    Incorporate Generative AI
                  </MenuItem>
                  <MenuItem value="deterai">
                    Mitigate use of Generative AI
                  </MenuItem>
                </Select>
              </Tooltip>
            </FormControl>

            {/* LLM Version */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="llm-version-label">
                Select the version of LLM
              </InputLabel>
              <Select
                labelId="llm-version-label"
                id="gpt_version"
                value={gptVersion}
                label="Select the version of LLM"
                onChange={(e) => setGptVersion(e.target.value)}
              >
                <MenuItem value="gpt-4-1106-preview">GPT-4</MenuItem>
                <MenuItem value="gpt-3.5-turbo" disabled>
                  GPT-3.5
                </MenuItem>
                {/* Add more versions as needed */}
              </Select>
            </FormControl>

            {/* Submit Button */}
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                Generate
              </Button>
            </Box>
          </form>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <div className="AssesmantGenMainInfo">
            <Typography variant="h5" component="h4">
              To create a customized assessment that aligns with your learning
              outcomes, begin by selecting one or more academic disciplines for
              targeted or interdisciplinary evaluation. Next, choose the student
              level (year 1-4, graduate) to adjust the complexity accordingly.
              Then, specify key topics, skills, and values to contextualize the
              assessment. Optionally, you may select a learning taxonomy and the
              level the assessment should target. Indicate whether the
              assessment is formative (process-focused) or summative
              (product-focused). Finally, choose whether the assessment should
              be tailored for students to utilize generative AI in completing
              the assignment or Design assessment to mitigate the use of AI generative tools.
            </Typography>
          </div>
          <Box>
            {loading && (
              <>
                {/* <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton animation="wave" /> */}
                <div className="scene pt2">
                  <div className="cube-wrapper">
                    <div className="cube">
                      <div className="cube-faces">
                        <div className="cube-face shadow"></div>
                        <div className="cube-face bottom"></div>
                        <div className="cube-face top"></div>
                        <div className="cube-face left"></div>
                        <div className="cube-face right"></div>
                        <div className="cube-face back"></div>
                        <div className="cube-face front"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div align="center">
                  {messages.map((message, index) => (
                    <Typography className="pt" key={index}>
                      {message}
                    </Typography>
                  ))}
                </div>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Box
          ref={resultContentsRef}
          mt={2}
          dangerouslySetInnerHTML={{ __html: marked(resultContents) }}
          className="pt3 resultsBox"
        />
        {/* <Box mt={2} dangerouslySetInnerHTML={{ __html: AssesmentResult }} />                 */}
        {showSaveButton && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveAndShare}
            disabled={saveButtonDisabled}
          >
            Save and Share
          </Button>
        )}
        {showPrintButton && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handlePrint}
            style={{ marginLeft: "10px" }}
          >
            Print
          </Button>
        )}
      </Box>
    </>
  );
}

export default MainForm;
