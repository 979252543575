import React, { useState, useEffect } from "react";
import {
    Grid,
    Typography,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Autocomplete,
    Alert,
    Tooltip,
    Card,
    CardContent,
    Radio,
    RadioGroup,
    FormControlLabel,
    Divider,
    Snackbar,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import config from "../config";
import MCQVerification from "./MCQVerification";

function MCQMaster() {
    const [numberOfQuestions, setNumberOfQuestions] = useState(5);
    const [difficulty, setDifficulty] = useState("medium");
    const [userLevels, setUserLevels] = useState([]);
    const [mainFields, setMainFields] = useState([]);
    const [selectedUserLevel, setSelectedUserLevel] = useState("");
    const [selectedMainField, setSelectedMainField] = useState("");
    const [topicField, setTopicField] = useState("");
    const [skillField, setSkillField] = useState("");
    const [loading, setLoading] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [showPrintButton, setShowPrintButton] = useState(false);
    const [messages, setMessages] = useState([]);
    const [modelInfo, setModelInfo] = useState("");
    const [taxonomyModels, setTaxonomyModels] = useState({});
    const [selectedTaxonomyModel, setSelectedTaxonomyModel] = useState("");
    const [questions, setQuestions] = useState([]);
    const [fileId, setFileId] = useState(null);
    const [showVerification, setShowVerification] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [mcqTitle, setMcqTitle] = useState("");
    const [showAnswers, setShowAnswers] = useState(false);
    const [ws, setWs] = useState(null);
    const [resultContents, setResultContents] = useState("");
    const [token, setToken] = useState(null);

    useEffect(() => {
        const WS = new WebSocket(`${config.appUrl}/ws`);
        WS.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log("Message from server:", data);
            if (data.status === "processing") {
                setMessages([data.message]);
            } else if (data.state === "Success") {
                setFileId(data.file_id);
                // Fetch the generated MCQ data
                fetchMCQ(data.file_id);
                setLoading(false);
            } else if (data.state === "done") {
                setShowSaveButton(true);
                setShowPrintButton(true);
            }
            if (data.error) {
                console.error("Error:", data.error);
            }
        };
        setWs(WS);

        return () => {
            if (WS && WS.readyState === WebSocket.OPEN) {
                WS.close();
            }
        };
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        setMessages(["Generating multiple-choice questions..."]);

        const formData = {
            topic: topicField,
            numberOfQuestions,
            difficulty,
            userLevel: selectedUserLevel,
            mainField: selectedMainField,
            skillField,
            taxonomyModel: selectedTaxonomyModel,
            formType: "mcq",
        };

        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify(formData));
        } else {
            console.error("WebSocket is not connected");
            setMessages(["Error: WebSocket is not connected"]);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const toggleAnswers = () => {
        setShowAnswers(!showAnswers);
    };

    const fetchData = async () => {
        try {
            const response = await fetch(`${config.webUrl}/api/data`);
            const data = await response.json();
            setUserLevels(data.user_level);
            setMainFields(data.main_field);
            setTaxonomyModels(data.taxonomies);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    const fetchMCQ = async (id) => {
        try {
            const response = await fetch(`${config.webUrl}/mcq/${id}`);
            if (!response.ok) {
                throw new Error("Failed to fetch MCQ data");
            }
            const mcqData = await response.json();
            setMcqTitle(mcqData.title);
            setQuestions(mcqData.questions);
        } catch (error) {
            console.error("Error fetching MCQ:", error);
            setMessages(["An error occurred while fetching the questions."]);
        }
    };

    const handleValidateMCQs = async () => {
        for (let i = 0; i < questions.length; i++) {
            await verifyQuestion(i);
        }
    };

    const verifyQuestion = async (index) => {
        setVerificationStatus((prev) => ({
            ...prev,
            [index]: { status: "verifying" },
        }));
        try {
            const response = await fetch(
                `${config.webUrl}/verify-mcq-answers`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        question: questions[index].question,
                        selected_answer:
                            questions[index].options[
                                questions[index].correctAnswer
                            ],
                    }),
                },
            );
            const data = await response.json();
            setVerificationStatus((prev) => ({
                ...prev,
                [index]: {
                    status: data.result,
                    explanation: data.explanation,
                },
            }));
        } catch (error) {
            console.error("Error verifying question:", error);
            setVerificationStatus((prev) => ({
                ...prev,
                [index]: {
                    status: "re-verify",
                    explanation:
                        "An error occurred during verification. Please try again.",
                },
            }));
        }
    };

    const handleNumberOfQuestionsChange = (event) => {
        const value = parseInt(event.target.value, 10);
        setNumberOfQuestions(value);
        if (value > 10) {
            setModelInfo(
                "Generating more than 10 questions may take longer due to the verification and overlap checking process.",
            );
        } else {
            setModelInfo("");
        }
    };

    const handleSaveAndShare = async () => {
        if (fileId) {
            try {
                const response = await fetch(
                    `${config.webUrl}/mcq/save-and-share/${fileId}`,
                    {
                        method: "POST",
                    },
                );
                if (!response.ok) {
                    throw new Error("Failed to save and share MCQ");
                }
                const data = await response.json();
                const shareableLink = `${window.location.origin}/mcq/${fileId}`;
                setSnackbarMessage(`MCQ saved and shared`);
                setSnackbarOpen(true);
            } catch (error) {
                console.error("Error saving and sharing MCQ:", error);
                setSnackbarMessage("Failed to save and share MCQ");
                setSnackbarOpen(true);
            }
        } else {
            console.error("No file ID available");
            setSnackbarMessage("No file ID available");
            setSnackbarOpen(true);
        }
    };

    const handlePrint = () => {
        const printWindow = window.open("", "_blank");
        printWindow.document.write(`
            <html>
                <head>
                    <title>Print MCQ</title>
                    <style>
                        body { font-family: Arial, sans-serif; }
                        .question { margin-bottom: 20px; }
                        .options { margin-left: 20px; }
                        .reason { font-style: italic; color: #666; }
                    </style>
                </head>
                <body>
                    ${questions
                        .map(
                            (q, index) => `
                        <div class="question">
                            <h3>${index + 1}. ${q.question}</h3>
                            <div class="options">
                                ${q.options
                                    .map(
                                        (option, i) => `
                                    <p>${String.fromCharCode(65 + i)}. ${option}</p>
                                `,
                                    )
                                    .join("")}
                            </div>
                            <p><strong>Correct Answer:</strong> ${String.fromCharCode(65 + q.correctAnswer)}</p>
                            <p class="reason"><strong>Explanation:</strong> ${q.reason}</p>
                        </div>
                    `,
                        )
                        .join("")}
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.print();
    };

    return (
        <div className="container mx-auto">
            <h1 className="text-3xl font-bold my-4">
                Multiple Choice Question Generator
            </h1>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <form onSubmit={handleSubmit}>
                        {/* Select Main Field */}
                        <FormControl fullWidth margin="normal">
                            <Tooltip
                                title="Select a Discipline. This field is searchable."
                                placement="right"
                            >
                                <Autocomplete
                                    id="mainFields"
                                    options={mainFields}
                                    getOptionLabel={(option) =>
                                        option.optionText
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select a Discipline"
                                        />
                                    )}
                                    value={
                                        mainFields.find(
                                            (option) =>
                                                option.optionValue ===
                                                selectedMainField,
                                        ) || null
                                    }
                                    onChange={(event, newValue) => {
                                        setSelectedMainField(
                                            newValue
                                                ? newValue.optionValue
                                                : "",
                                        );
                                    }}
                                />
                            </Tooltip>
                        </FormControl>

                        {/* Select User Level */}
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="user-level-label">
                                Level of study
                            </InputLabel>
                            <Select
                                labelId="user-level-label"
                                id="userLevels"
                                value={selectedUserLevel}
                                label="Select User Level"
                                onChange={(e) =>
                                    setSelectedUserLevel(e.target.value)
                                }
                            >
                                {userLevels.map((item) => (
                                    <MenuItem
                                        value={item.optionValue}
                                        key={item.optionValue}
                                    >
                                        {item.optionText}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* TopicField */}
                        {selectedMainField !== "other" &&
                            selectedMainField !== "none" &&
                            selectedMainField !== "" && (
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        id="Topic_field"
                                        label="Topic within the discipline (optional)"
                                        variant="outlined"
                                        value={topicField}
                                        placeholder="e.g. Algebra, Geometry, etc."
                                        onChange={(e) =>
                                            setTopicField(e.target.value)
                                        }
                                    />
                                </FormControl>
                            )}

                        {/* Conditionally Rendered skills */}
                        {selectedMainField !== "other" &&
                            selectedMainField === "none" &&
                            selectedMainField !== "" && (
                                <FormControl fullWidth margin="normal">
                                    <Tooltip
                                        title="Enter specific skills, competencies, or specializations related to the assessment (optional)"
                                        placement="right"
                                    >
                                        <TextField
                                            id="skills_field"
                                            label="Skills | Competencies | Specialization (optional)"
                                            variant="outlined"
                                            value={skillField}
                                            placeholder="e.g. Data Analysis, Critical Thinking, etc."
                                            onChange={(e) =>
                                                setSkillField(e.target.value)
                                            }
                                        />
                                    </Tooltip>
                                </FormControl>
                            )}

                        {/* Learning Taxonomy or Model Dropdown */}
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="taxonomy-model-label">
                                Learning Taxonomy or Model
                            </InputLabel>
                            <Tooltip
                                title="Educational taxonomies are frameworks used to classify learning objectives and outcomes across different levels of complexity, from basic knowledge acquisition to advanced critical thinking skills."
                                placement="right"
                            >
                                <Select
                                    labelId="taxonomy-model-label"
                                    id="taxonomyModels"
                                    value={selectedTaxonomyModel}
                                    label="Learning Taxonomy or Model"
                                    onChange={(e) =>
                                        setSelectedTaxonomyModel(e.target.value)
                                    }
                                >
                                    {Object.entries(taxonomyModels).map(
                                        ([group, items]) => [
                                            <MenuItem
                                                value={group}
                                                key={group}
                                                disabled
                                                style={{ fontWeight: "bold" }}
                                            >
                                                {group}
                                            </MenuItem>,
                                            ...items.map((item) => (
                                                <MenuItem
                                                    value={item.optionValue}
                                                    key={item.optionValue}
                                                    style={{
                                                        paddingLeft: "20px",
                                                    }}
                                                >
                                                    {item.optionText}
                                                </MenuItem>
                                            )),
                                        ],
                                    )}
                                </Select>
                            </Tooltip>
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <InputLabel>Number of Questions</InputLabel>
                            <Select
                                value={numberOfQuestions}
                                onChange={handleNumberOfQuestionsChange}
                                label="Number of Questions"
                                required
                            >
                                {[
                                    5, 10, 15, 20, 25, 30, 40, 45, 50, 55, 60,
                                ].map((num) => (
                                    <MenuItem key={num} value={num}>
                                        {num}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <InputLabel>level of difficulty</InputLabel>
                            <Select
                                value={difficulty}
                                onChange={(e) => setDifficulty(e.target.value)}
                                label="level of difficulty"
                                required
                            >
                                <MenuItem value="easy">Easy</MenuItem>
                                <MenuItem value="medium">Medium</MenuItem>
                                <MenuItem value="hard">Hard</MenuItem>
                            </Select>
                        </FormControl>

                        {modelInfo && (
                            <Alert
                                severity="info"
                                style={{ marginTop: "10px" }}
                            >
                                {modelInfo}
                            </Alert>
                        )}

                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={loading}
                            >
                                Generate MCQs
                            </Button>
                        </Box>
                    </form>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className="AssesmantGenMainInfo">
                        <Typography variant="h5" component="h4">
                            Generate targeted multiple-choice questions by
                            specifying the subject, topic, learning objectives
                            (using Bloom's Taxonomy or a similar framework),
                            desired number of questions, difficulty level, and
                            intended study level. The tool employs a systematic
                            process to ensure question quality and alignment
                            with learning outcomes, ensuring each MCQ
                            contributes to a meaningful assessment.
                            Additionally, we have implemented a verification
                            process for each question and its corresponding
                            answer to ensure accuracy.
                        </Typography>
                    </div>
                    <Box>
                        {loading && (
                            <>
                                <div className="scene pt2">
                                    <div className="cube-wrapper">
                                        <div className="cube">
                                            <div className="cube-faces">
                                                <div className="cube-face shadow"></div>
                                                <div className="cube-face bottom"></div>
                                                <div className="cube-face top"></div>
                                                <div className="cube-face left"></div>
                                                <div className="cube-face right"></div>
                                                <div className="cube-face back"></div>
                                                <div className="cube-face front"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div align="center">
                                    {messages.map((message, index) => (
                                        <Typography className="pt" key={index}>
                                            {message}
                                        </Typography>
                                    ))}
                                </div>
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>
            <Box mt={4}>
                {questions.length > 0 && (
                    <div>
                        <Typography variant="h6" gutterBottom>
                            Generated MCQs:
                        </Typography>
                        <Box display="flex" justifyContent="flex-end" mb={2}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleValidateMCQs}
                                style={{ marginRight: "10px" }}
                            >
                                Validate MCQs
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={toggleAnswers}
                                startIcon={
                                    showAnswers ? (
                                        <VisibilityOffIcon />
                                    ) : (
                                        <VisibilityIcon />
                                    )
                                }
                            >
                                {showAnswers ? "Hide Answers" : "Show Answers"}
                            </Button>
                        </Box>
                        {questions.map((q, index) => (
                            <Card
                                key={index}
                                sx={{ mb: 2, position: "relative" }}
                            >
                                <CardContent className="singleCardContents">
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: 10,
                                            right: 10,
                                        }}
                                    >
                                        <Tooltip
                                            title={
                                                verificationStatus[index]
                                                    ?.explanation || ""
                                            }
                                        >
                                            <span>
                                                <MCQVerification
                                                    status={
                                                        verificationStatus[
                                                            index
                                                        ]?.status
                                                    }
                                                    onVerify={() =>
                                                        verifyQuestion(index)
                                                    }
                                                />
                                            </span>
                                        </Tooltip>
                                    </Box>
                                    <Typography variant="h6" gutterBottom>
                                        {index + 1}. {q.question}
                                    </Typography>
                                    <RadioGroup>
                                        {q.options.map((option, i) => (
                                            <FormControlLabel
                                                key={i}
                                                value={String(i)}
                                                control={<Radio />}
                                                label={option}
                                                disabled
                                            />
                                        ))}
                                    </RadioGroup>
                                    {showAnswers && (
                                        <>
                                            <Typography
                                                variant="body1"
                                                color="primary"
                                                sx={{ mt: 1 }}
                                            >
                                                Correct Answer:{" "}
                                                {String.fromCharCode(
                                                    65 + q.correctAnswer,
                                                )}
                                            </Typography>
                                            <Divider sx={{ my: 1 }} />
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                Explanation: {q.reason}
                                            </Typography>
                                        </>
                                    )}
                                </CardContent>
                            </Card>
                        ))}
                        <Box mt={2}>
                            {showSaveButton && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSaveAndShare}
                                    style={{ marginRight: "10px" }}
                                >
                                    Save and Share
                                </Button>
                            )}
                            {showPrintButton && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handlePrint}
                                >
                                    Print
                                </Button>
                            )}
                        </Box>
                    </div>
                )}
            </Box>
            {showVerification && <MCQVerification questions={questions} />}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
        </div>
    );
}

export default MCQMaster;
