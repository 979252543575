import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  Stack,
  Tab,
  Tabs,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
} from "@mui/material";
import DOMPurify from "dompurify";
import config from "../config";
import { marked } from "marked";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import EditNoteIcon from "@mui/icons-material/EditNote";

function TextModal({ open, onClose, onSubmit, selectedText }) {
  const [inputText, setInputText] = useState("");

  const handleSubmit = useCallback(() => {
    onSubmit(inputText);
    onClose();
  }, [inputText, onSubmit, onClose]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      } else if (event.key === "Escape") {
        onClose();
      }
    },
    [handleSubmit, onClose],
  );

  useEffect(() => {
    if (open) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, handleKeyDown]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <Typography
          className="user_request_context"
          sx={{
            wordWrap: "break-word",
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
            marginBottom: 2,
          }}
        >
          {selectedText}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ position: "relative" }}
        >
          <TextField
            autoFocus
            margin="none"
            label="How can I assist?"
            fullWidth
            multiline
            rows={3}
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <IconButton
            aria-label="submitAI"
            onClick={handleSubmit}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: "50%",
              transform: "translateY(-50%)",
              color: theme.palette.grey[500],
            })}
          >
            <TipsAndUpdatesIcon />
          </IconButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

function AssessmentDetail() {
  const [assessment, setAssessment] = useState(null);
  const [assessmentSections, setAssessmentSections] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [gradingResults, setGradingResults] = useState("");
  const [alternativeResults, setAlternativeResults] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [selectedElementId, setSelectedElementId] = useState("");
  const [sectionLoading, setSectionLoading] = useState({});
  const [showPrintButton, setShowPrintButton] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${config.webUrl}/assessments/${id}`);
        if (!response.ok) {
          throw new Error("Assessment not found");
        }
        const data = await response.json();
        setAssessment(data);
        setShowPrintButton(true);
        // Parse the output into sections
        const sections = parseContentIntoSections(data.output);
        setAssessmentSections(sections);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const parseContentIntoSections = (content) => {
    const tagTypes = ["p", "h1", "h2", "h3", "h4", "ul", "ol", "table"];
    const tagPattern = new RegExp(
      `(<(${tagTypes.join("|")}).*?>.*?</\\2>)`,
      "gs",
    );
    const sections = content.split(tagPattern);
    const result = [];

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      if (section.trim()) {
        const match = section.match(/<(\w+).*?>/);
        if (match && tagTypes.includes(match[1])) {
          result.push({
            id: `section-${result.length}`,
            content: section,
            type: match[1],
          });
        } else if (result.length > 0) {
          // If it's not a matched tag, append to the previous section
          // result[result.length - 1].content += section;
        } else {
          // If there's content before the first matched tag, create a new section for it
          result.push({
            id: `section-${result.length}`,
            content: section,
            type: "other",
          });
        }
      }
    }

    return result;
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handlePrint = () => {
    // Create an iframe element
    const iframe = document.createElement("iframe");
    document.body.appendChild(iframe);

    // Style the iframe to be hidden
    iframe.style.position = "absolute";
    iframe.style.width = "0";
    iframe.style.height = "0";
    iframe.style.border = "0";

    // Get the iframe document
    const printDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    printDocument.open();

    // Write the content to the iframe document
    printDocument.write(`
      <html>
        <head>
          <title>Assessment-Partner Generated - @McMaster</title>
        </head>
        <body>
          ${marked(assessment.output)}
        </body>
      </html>
    `);
    printDocument.close();

    // Print the iframe content
    iframe.contentWindow.focus();
    iframe.contentWindow.print();

    // Remove the iframe after printing
    document.body.removeChild(iframe);
  };

  const openModal = useCallback((text, elementId) => {
    setSelectedText(text);
    setSelectedElementId(elementId);
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleModalSubmit = useCallback(
    async (inputText) => {
      try {
        setSectionLoading((prev) => ({ ...prev, [selectedElementId]: true }));

        const response = await fetch(`${config.webUrl}/assessments/rewrite`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            assessmentId: id,
            selectedText: selectedText,
            userInput: inputText,
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to rewrite section");
        }

        const data = await response.json();
        const rewrittenText = data.rewrittenText;

        setAssessmentSections((prevSections) =>
          prevSections.map((section) =>
            section.id === selectedElementId
              ? { ...section, content: rewrittenText }
              : section,
          ),
        );

        setSectionLoading((prev) => ({ ...prev, [selectedElementId]: false }));
        closeModal();
      } catch (error) {
        console.error("Error rewriting section:", error);
        setSectionLoading((prev) => ({ ...prev, [selectedElementId]: false }));
      }
    },
    [id, selectedElementId, selectedText, closeModal],
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${config.webUrl}/assessments/${id}`);
        if (!response.ok) {
          throw new Error("Assessment not found");
        }
        const data = await response.json();
        setAssessment(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    const WS = new WebSocket(`${config.appUrl}/ws`);
    WS.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("Message from server:", data);
      if (data.status === "processing") {
        console.log(data.message);
        setMessages([data.message]);
      } else if (data.state === "Success") {
        const newMessage = data.content;
        setGradingResults(newMessage);
      }
      if (data.error) {
        console.error("Error:", data.error);
      }
    };
  }, [id]);

  const generateAlternative = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${config.webUrl}/assessments/alternative`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ assessmentId: id }),
      });

      if (!response.ok) {
        throw new Error("Failed to generate alternative");
      }

      const data = await response.json();
      setAlternativeResults(data.alternative);
      setActiveTab(1);
    } catch (error) {
      console.error("Error generating alternative:", error);
    }
    setLoading(false);
  };

  const fileInputRef = useRef(null);

  const triggerFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file.name.match(/\.(docx|pdf|txt)$/)) {
      alert("Only .docx, .pdf, and .txt files are allowed.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("assignment_file", file);
      formData.append("assignment_id", id);

      const response = await fetch(`${config.webUrl}/api/upload_assignment`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("File upload failed");
      }

      console.log("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  if (!assessment) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Stack spacing={2} direction="row">
        <Button
          variant="outlined"
          onClick={() => navigate("/assessment/listall")}
        >
          Back to List
        </Button>
        <Button variant="contained" onClick={generateAlternative}>
          Generate Alternative
        </Button>
        {/* <Button variant="contained" onClick={triggerFileUpload}>
          Upload Assignment
        </Button> */}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileUpload}
          accept=".docx,.pdf,.txt"
        />
      </Stack>
      <div className="space2"></div>
      {loading && (
        <div align="center">
          <Skeleton animation="wave" />
          <Typography style={{ color: "#d35980", fontSize: "1.3em" }}>
            Generating alternative assessment...
          </Typography>
          <Skeleton animation="pulse" />
          <Skeleton animation="wave" />
        </div>
      )}
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Assessment" />
        {alternativeResults && <Tab label="Alternative Assessment" />}
      </Tabs>
      {activeTab === 0 && (
        <div className="assess-left-col">
          {assessmentSections.map((section) => (
            <div key={section.id} className="hoverable-section">
              {sectionLoading[section.id] && (
                <div className="selectionloader">
                  <CircularProgress size={26} />
                </div>
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html: marked(DOMPurify.sanitize(section.content)),
                }}
                onMouseEnter={(e) =>
                  e.currentTarget.parentNode.classList.add("hovered")
                }
                onMouseLeave={(e) =>
                  e.currentTarget.parentNode.classList.remove("hovered")
                }
              />
              <IconButton
                className="edit-icon"
                aria-label="EditText"
                onClick={() => openModal(section.content, section.id)}
              >
                <EditNoteIcon />
              </IconButton>
            </div>
          ))}
        </div>
      )}
      {activeTab === 1 && (
        <div className="assess-right-col">
          <Box
            mt={2}
            dangerouslySetInnerHTML={{ __html: marked(alternativeResults) }}
          />
        </div>
      )}
      <div align="center">
        {messages.map((message, index) => (
          <Typography className="pt2" key={index}>
            {message}
          </Typography>
        ))}
      </div>
      <Box
        mt={2}
        dangerouslySetInnerHTML={{ __html: marked(gradingResults) }}
      />

      {/* Add Print Button */}
      {showPrintButton && (
        <Box mt={2}>
          <Button variant="contained" color="secondary" onClick={handlePrint}>
            Print
          </Button>
        </Box>
      )}

      <TextModal
        open={modalOpen}
        onClose={closeModal}
        onSubmit={handleModalSubmit}
        selectedText={selectedText}
      />
    </div>
  );
}

export default AssessmentDetail;
