import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
    CardActionArea,
    Typography,
    CardMedia,
    CardContent,
    Card,
} from "@mui/material";

export default function ActionAreaCard() {
    const navigate = useNavigate();

    const navigateToInvolved = () => {
        navigate("/involved");
    };

    const navigateToSimple = () => {
        navigate("/simple");
    };

    const navigateToList = () => {
        navigate("/assessment/listall");
    };

    const navigateToMCQ = () => {
        navigate("/mcq");
    };

    return (
        <div align="center">
            <div
                style={{
                    display: "flex",
                    gap: "40px",
                    justifyContent: "center",
                    flexWrap: "wrap",
                }}
            >
                <Card sx={{ maxWidth: 300 }}>
                    <CardActionArea onClick={navigateToSimple}>
                        <CardMedia
                            component="img"
                            height="200"
                            image="/simple.png"
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                            >
                                Simple Assessment Generation
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Unsure of where to begin? With just few clicks,
                                you can create an assessment:.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <Card sx={{ maxWidth: 300 }}>
                    <CardActionArea onClick={navigateToInvolved}>
                        <CardMedia
                            component="img"
                            height="200"
                            image="/complex.png"
                            alt="another image"
                        />
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                            >
                                Involved Assessment Generation
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Do you have a more detailed idea of what you
                                want for your assessment? This option offers
                                more control over the specificity of your
                                assessment.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <Card sx={{ maxWidth: 300 }}>
                    <CardActionArea onClick={navigateToMCQ}>
                        <CardMedia
                            component="img"
                            height="200"
                            image="/mcq.png"
                            alt="multiple choice questions"
                        />
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                            >
                                Multiple Choice Questions
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Generate multiple-choice questions tailored to
                                your specific topic and learning objectives.
                                Perfect for quizzes and exams.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <Card sx={{ maxWidth: 300 }}>
                    <CardActionArea onClick={navigateToList}>
                        <CardMedia
                            component="img"
                            height="200"
                            image="/list_assessments.png"
                            alt="assessment list - showig a grid of trees"
                        />
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                            >
                                List Generated Assessments
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Review assessments that have been previously
                                created by and shared by users. You will also
                                have the option to generate alternative
                                assessments based on the existing ones.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>
        </div>
    );
}
