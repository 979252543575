const config = {
  webUrl: "https://aipartnerapi.replit.app",
  appUrl: "wss://aipartnerapi.replit.app",
};

// const config = {
//     webUrl: "http://127.0.0.1:8000",
//     appUrl: "ws://127.0.0.1:8000",
//   };
export default config;
