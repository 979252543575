import React from "react";
import { Grid, Button, Box, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

function LandingPage() {
  const navigate = useNavigate();

  const handleNavigate = (url) => {
    window.location.href = url;
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} align="center">
        <div className="LandingPageTitle" align="center">
          Assessment Partner
        </div>
        <Typography variant="h5" className="LandingPageSubText pb2">
          This proof of concept application employs Generative AI and a multi-agent approach to craft assessments aligned with your teaching objectives. Designed with educational best practices in mind, its interface streamlines the development of unique, outcome-focused assessments. It provides educators with a powerful tool to enhance learning engagement and achievement.
        </Typography>
        <Box mt={2} mb={2}>
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate("/select")}
          >
            Create an Assessment
          </Button>
        </Box>
        {/* New splash message and link */}
        <Box mt={4} mb={2}>
          <Typography variant="h5" color="primary" fontWeight="bold">
          NEW Assessment Feature Added: Create Customized Multiple-Choice Questions that Align with Learning Objectives.
          </Typography>
          <Typography variant="h5" color="primary" fontWeight="bold">
          <Link href="/mcq" color="secondary">
            Try it now!
          </Link></Typography>
        </Box>
      </Grid>
      {/* Logo placement */}
      <div className="pt4">
        <Grid item container spacing={4} justifyContent="center">
          <Grid item>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              component="img"
              src="/macpherson-logo.png"
              sx={{ width: 380, height: "auto", cursor: "pointer" }}
              onClick={() => handleNavigate("https://mi.mcmaster.ca/")}
            />
          </Grid>
          <Grid item>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              component="img"
              src="/mcmaster_logo.png"
              sx={{ width: 200, height:"auto", cursor: "pointer" }}
              onClick={() => handleNavigate("https://mcmaster.ca")}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}

export default LandingPage;