// Footer.js
import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box component="footer" sx={{ bgcolor: 'background.paper', py: 6 }}>
      <Typography variant="body2" color="textSecondary" align="center">
        © {new Date().getFullYear()} Assessment Partner | McMaster University -
        MacPherson Institute
      </Typography>
    </Box>
  );
}

export default Footer;
