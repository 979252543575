import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Typography,
    Card,
    CardContent,
    Radio,
    RadioGroup,
    FormControlLabel,
    Divider,
    Box,
    Button,
    CircularProgress,
    Container,
    Snackbar,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import config from '../config';
import MCQVerification from './MCQVerification';

function MCQDetail() {
    const [mcqData, setMcqData] = useState({ title: '', questions: [] });
    const [loading, setLoading] = useState(true);
    const [verificationStatus, setVerificationStatus] = useState({});
    const [showAnswers, setShowAnswers] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMCQ = async () => {
            try {
                const response = await fetch(`${config.webUrl}/mcqshared/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch MCQ data');
                }
                const data = await response.json();
                setMcqData(data);
            } catch (error) {
                console.error('Error fetching MCQ:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMCQ();
    }, [id]);

    const verifyQuestion = async (index) => {
        setVerificationStatus(prev => ({ ...prev, [index]: { status: 'verifying' } }));
        try {
            const response = await fetch(`${config.webUrl}/verify-mcq-answers`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    question: mcqData.questions[index].question,
                    selected_answer: mcqData.questions[index].options[mcqData.questions[index].correctAnswer],
                }),
            });
            const data = await response.json();
            setVerificationStatus(prev => ({ 
                ...prev, 
                [index]: { 
                    status: data.result, 
                    explanation: data.explanation 
                } 
            }));
        } catch (error) {
            console.error('Error verifying question:', error);
            setVerificationStatus(prev => ({ 
                ...prev, 
                [index]: { 
                    status: 're-verify', 
                    explanation: 'An error occurred during verification. Please try again.' 
                } 
            }));
        }
    };

    const handlePrint = () => {
        window.print();
    };

    const toggleAnswers = () => {
        setShowAnswers(!showAnswers);
    };

    const handleValidateMCQs = async () => {
        for (let i = 0; i < mcqData.questions.length; i++) {
            await verifyQuestion(i);
        }
    };

    const handleSaveAndShare = async () => {
        try {
            const response = await fetch(`${config.webUrl}/mcq/save-and-share/${id}`, {
                method: 'POST',
            });
            if (!response.ok) {
                throw new Error('Failed to save and share MCQ');
            }
            const data = await response.json();
            const shareableLink = `${window.location.origin}/mcq/${id}`;
            setSnackbarMessage(`MCQ saved and shared. Link: ${shareableLink}`);
            setSnackbarOpen(true);
        } catch (error) {
            console.error("Error saving and sharing MCQ:", error);
            setSnackbarMessage("Failed to save and share MCQ");
            setSnackbarOpen(true);
        }
    };

    if (loading) {
        return (
            <Container>
                <CircularProgress />
                <Typography>Loading MCQ data...</Typography>
            </Container>
        );
    }

    return (
        <div>
            <Button variant="outlined" onClick={() => navigate(-1)} style={{ marginBottom: '20px' }}>
                Back to List
            </Button>
            <Typography variant="h4" gutterBottom>{mcqData.title || 'Multiple Choice Questions'}</Typography>
            <Typography variant="subtitle1" gutterBottom>Total Questions: {mcqData.questions.length}</Typography>
            
            <Box display="flex" justifyContent="space-between" mb={2}>
                <Button variant="contained" color="primary" onClick={handlePrint}>
                    Print
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={toggleAnswers}
                    startIcon={showAnswers ? <VisibilityOffIcon /> : <VisibilityIcon />}
                >
                    {showAnswers ? 'Hide Answers' : 'Show Answers'}
                </Button>
            </Box>
            
            <Box mt={4}>
                {mcqData.questions.map((q, index) => (
                    <Card key={index} sx={{ mb: 2, position: 'relative' }}>
                        <CardContent className='singleCardContents'>
                            <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
                                <MCQVerification
                                    status={verificationStatus[index]?.status}
                                    onVerify={() => verifyQuestion(index)}
                                />
                            </Box>
                            <Typography variant="h6" gutterBottom>
                                Question {index + 1} of {mcqData.questions.length}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {q.question}
                            </Typography>
                            <RadioGroup>
                                {q.options.map((option, i) => (
                                    <FormControlLabel
                                        key={i}
                                        value={String(i)}
                                        control={<Radio />}
                                        label={option}
                                        disabled
                                    />
                                ))}
                            </RadioGroup>
                            {showAnswers && (
                                <>
                                    <Typography variant="body1" color="primary" sx={{ mt: 1 }}>
                                        Correct Answer: {String.fromCharCode(65 + q.correctAnswer)}
                                    </Typography>
                                    <Divider sx={{ my: 1 }} />
                                    <Typography variant="body2" color="text.secondary">
                                        Explanation: {q.reason}
                                    </Typography>
                                </>
                            )}
                        </CardContent>
                    </Card>
                ))}
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
        </div>
    );
}

export default MCQDetail;