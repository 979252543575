import React, { useState, useEffect, useRef } from "react";
import {
    FormControl,
    Grid,
    InputLabel,
    Typography,
    Select,
    MenuItem,
    TextField,
    Button,
    Box,
    Autocomplete,
    Skeleton,
    Tooltip,
} from "@mui/material";
import "../App.css";
import config from "../config";
import { marked } from "marked";

function SimpleForm() {
    // const [assignments, setAssignments] = useState([]);
    const [customPrompts, setCustomPrompts] = useState([]);
    const [userLevels, setUserLevels] = useState([]);
    const [mainFields, setMainFields] = useState([]);
    const [assignmentGroups, setAssignmentGroups] = useState({});
    const [TypeOfAssessments, setTypeOfAssessments] = useState([]);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ws, setWs] = useState(null);
    const [selectedAssignment, setSelectedAssignment] = useState("");
    const [selectedCustomPrompt, setSelectedCustomPrompt] = useState("");
    const [selectedUserLevel, setSelectedUserLevel] = useState("");
    const [selectedMainField, setSelectedMainField] = useState("");
    const [TopicField, setTopicField] = useState("");
    const [AssesmentResult, setAssesmentResult] = useState("");
    const [resultContents, setResultContents] = useState("");
    const [selectedAssignments, setSelectedAssignments] = useState([]);

    const [showSaveButton, setShowSaveButton] = useState(false);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [token, setToken] = useState(null);
    const [showPrintButton, setShowPrintButton] = useState(false);

    const resultContentsRef = useRef(null);

    // Example useEffect to fetch data from API
    useEffect(() => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth",
        });

        fetch(`${config.webUrl}/api/data`)
            .then((response) => response.json())
            .then((data) => {
                console.log("Data fetched:", data);
                setUserLevels(data.user_level);
                setMainFields(data.main_field);
                setTypeOfAssessments(data.type_of_assessment);

                // Assuming you want to combine both Assignments and LessonsAndAssessments
                // setAssignments([...data.assignments.Assignments]);
                setAssignmentGroups({
                    // "Custom Prompts": data.assignments.CustomPrompts, // Update this if the structure is different
                    Components: data.assignments.Componets,
                    // "Lessons And Assessments":
                    //     data.assignments.LessonsAndAssessments,
                    // "Learning Styles": data.assignments.LearningStyles,
                });
                setCustomPrompts(data.custom_prompts); // Update this if 'custom_prompts' structure is different
            })
            .catch((error) => {
                console.error("Error fetching data:", error.message);
            });

        const WS = new WebSocket(`${config.appUrl}/ws`);
        WS.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log("Message from server:", data);
            if (data.status === "processing") {
                setMessages([data.message]);
            } else if (data.state === "Success") {
                const newMessage = data.content;
                setResultContents(newMessage);
                setLoading(false);
                setToken(data.token);
            } else if (data.state === "done") {
                setShowSaveButton(true);
                setShowPrintButton(true);
            }
            // Handle any error messages
            if (data.error) {
                console.error("Error:", data.error);
            }
        };
        setWs(WS);

        // Clean up on component unmount
        return () => {
            if (WS && WS.readyState === WebSocket.OPEN) {
                WS.close(); // Close only if the connection is open
            }
        };
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = {
            assignmentTypes: selectedAssignments.map(
                (assignment) => assignment.optionValue
            ),
            customPrompt: selectedCustomPrompt,
            userLevel: selectedUserLevel,
            mainField: selectedMainField,
            TypeOfAssessment: "none",
            skillField: "none",
            TopicField: TopicField,
            genAiUse: "none",
            gptVersion: "gpt-4-1106-preview",
            formType: "simple",
        };

        if (ws) {
            ws.send(JSON.stringify(formData));
            setLoading(true);
        }
    };

    const handleSaveAndShare = () => {
        setSaveButtonDisabled(true);

        fetch(`${config.webUrl}/assessments/save`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ token }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Assignment saved:", data);
            })
            .catch((error) => {
                console.error("Error saving assignment:", error);
            });
    };

    const handlePrint = () => {
        const printWindow = window.open("", "_blank");
        printWindow.document.open();
        printWindow.document.write(`
      <html>
        <head>
          <title>Print Assessment</title>
        </head>
        <body>
          ${resultContents}
        </body>
      </html>
    `);
        printWindow.document.close();
        printWindow.print();
    };

    const menuProps = {
        PaperProps: {
            style: {
                maxHeight: 300, // Maximum height for the dropdown
            },
        },
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                    <form onSubmit={handleSubmit}>
                        {/* Select Assessment Components */}
                        <Tooltip
                            title={<React.Fragment>Select one or more assessment components. <br/>- These educational assignment components can cater to a wide range of outcomes in student learning.</React.Fragment>}
                            placement="right"
                        >
                            <FormControl fullWidth margin="normal">
                                <Autocomplete
                                    multiple
                                    id="assignments"
                                    options={Object.entries(
                                        assignmentGroups
                                    ).flatMap(([group, items]) => items)}
                                    getOptionLabel={(option) =>
                                        option.optionText
                                    }
                                    groupBy={(option) => {
                                        const group = Object.entries(
                                            assignmentGroups
                                        ).find(([, items]) =>
                                            items.includes(option)
                                        );
                                        return group ? group[0] : "";
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Assessment Components"
                                        />
                                    )}
                                    value={selectedAssignments}
                                    onChange={(event, newValue) => {
                                        setSelectedAssignments(newValue);
                                    }}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            <Typography
                                                variant="subtitle1"
                                                style={{ fontWeight: "bold" }}
                                            >
                                                {params.group}
                                            </Typography>
                                            <ul>{params.children}</ul>
                                        </li>
                                    )}
                                />
                            </FormControl>
                        </Tooltip>

                        {/* Select User Level */}
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="user-level-label">
                                Level of study
                            </InputLabel>
                            <Select
                                labelId="user-level-label"
                                id="userLevels"
                                value={selectedUserLevel}
                                label="Select User Level"
                                onChange={(e) =>
                                    setSelectedUserLevel(e.target.value)
                                }
                            >
                                {userLevels.map((item) => (
                                    <MenuItem
                                        value={item.optionValue}
                                        key={item.optionValue}
                                    >
                                        {item.optionText}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Select Main Field */}
                        <FormControl fullWidth margin="normal">
                            <Tooltip
                                title="Select a Discipline. This field is searchable."
                                placement="right"
                            >
                                <Autocomplete
                                    id="mainFields"
                                    options={mainFields}
                                    getOptionLabel={(option) =>
                                        option.optionText
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select a Discipline"
                                        />
                                    )}
                                    value={mainFields.find(
                                        (option) =>
                                            option.optionValue ===
                                            selectedMainField
                                    )}
                                    onChange={(event, newValue) => {
                                        setSelectedMainField(
                                            newValue ? newValue.optionValue : ""
                                        );
                                    }}
                                />
                            </Tooltip>
                        </FormControl>

                        {/* TopicField */}
                        {selectedMainField !== "other" &&
                            selectedMainField !== "none" &&
                            selectedMainField !== "" && (
                                <Tooltip
                                    title="Enter a topic within the selected discipline (optional)"
                                    placement="right"
                                >
                                    <FormControl fullWidth margin="normal">
                                        <TextField
                                            id="Topic_field"
                                            label="Topic within the discipline (optional)"
                                            variant="outlined"
                                            value={TopicField}
                                            onChange={(e) =>
                                                setTopicField(e.target.value)
                                            }
                                        />
                                    </FormControl>
                                </Tooltip>
                            )}
                        {/* Submit Button */}
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={loading}
                            >
                                Generate
                            </Button>
                        </Box>
                    </form>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <div className="AssesmantGenMainInfo">
                        <Typography variant="h5" component="h4">
                        Select one or more assessment components and a discipline, including an optional topic if desired, along with the level of study and type of assessment, to generate a customized assessment.
                        </Typography>
                    </div>
                    <Box>
                        {loading && (
                            <>
                                {/* <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton animation="wave" /> */}
                                <div className="scene pt2">
                                    <div className="cube-wrapper">
                                        <div className="cube">
                                            <div className="cube-faces">
                                                <div className="cube-face shadow"></div>
                                                <div className="cube-face bottom"></div>
                                                <div className="cube-face top"></div>
                                                <div className="cube-face left"></div>
                                                <div className="cube-face right"></div>
                                                <div className="cube-face back"></div>
                                                <div className="cube-face front"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div align="center">
                                    {messages.map((message, index) => (
                                        <Typography className="pt" key={index}>
                                            {message}
                                        </Typography>
                                    ))}
                                </div>
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>
            <Box>
                <Box
                    ref={resultContentsRef}
                    mt={2}
                    dangerouslySetInnerHTML={{ __html: marked(resultContents) }}
                    className="pt3 resultsBox"
                />
                {showSaveButton && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveAndShare}
                        disabled={saveButtonDisabled}
                    >
                        Save and Share
                    </Button>
                )}
                {showPrintButton && (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handlePrint}
                        style={{ marginLeft: "10px" }}
                    >
                        Print
                    </Button>
                )}
            </Box>
        </>
    );
}

export default SimpleForm;
