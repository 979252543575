import React from 'react';
import SimpleForm from './SimpleForm';
import InfoSection from './InfoSection';

function SimpleAssessmentGenerator() {
    return (
        <div className="container mx-auto">
            <h1 className="text-3xl font-bold my-4">Assessment Partner</h1>
            <h3 className="text-xl mb-4">Simple assessment development</h3>
            <SimpleForm />
            <InfoSection />
        </div>
    );
}

export default SimpleAssessmentGenerator;
