// ThemeContext.js
import React, { createContext, useState, useMemo, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import WebFont from 'webfontloader';

WebFont.load({
    google: {
        families: ['Comfortaa:300,400,700', 'sans-serif']
    }
});

export const ThemeContext = createContext({
  toggleTheme: () => {},
  darkMode: true,
});

export const ThemeContextProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true');

  useEffect(() => {
    localStorage.setItem('darkMode', darkMode);
    // Apply a class to the body based on the current theme
    document.body.classList.toggle('light-mode', !darkMode);
  }, [darkMode]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
          background: {
            default: darkMode ? '#121212' : '#f7f7f7',
          },
        },
        typography: {
            fontFamily: [
                'Comfortaa', 'sans-serif'
            ].join(','),
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: '1.1rem',
                backgroundColor: darkMode ? '#333' : '#222',
              },
            },
          },
        },
      }),
    [darkMode]
  );

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeContext.Provider value={{ toggleTheme, darkMode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};