import React from 'react';
import MainForm from './MainForm';
import InfoSection from './InfoSection';

function AssignmentGenerator() {
    return (
        <div className="container mx-auto">
            <h1 className="text-3xl font-bold my-4">Assessment Partner</h1>
            <h3 className="text-xl mb-4">More involved assignment development</h3>
            <MainForm />
            <InfoSection />
        </div>
    );
}

export default AssignmentGenerator;
