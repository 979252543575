import React, { useState } from "react";
import {
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    Typography,
    Grid,
    Snackbar,
} from "@mui/material";
import { styled } from "@mui/system";
import config from "../config";

const StyledForm = styled("form")({
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    maxWidth: "500px",
    margin: "0 auto",
});

function Feedback() {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        feedbackType: "",
        message: "",
    });
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        // Clear the error for this field
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    const validateForm = () => {
        let tempErrors = {};
        tempErrors.name = formData.name ? "" : "Name is required";
        tempErrors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
            ? ""
            : "Email is invalid";
        tempErrors.feedbackType = formData.feedbackType
            ? ""
            : "Please select a feedback type";
        tempErrors.message = formData.message ? "" : "Message is required";
        setErrors(tempErrors);
        return Object.values(tempErrors).every((x) => x === "");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const response = await fetch(
                    `${config.webUrl}/submit-feedback`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(formData),
                    },
                );

                if (!response.ok) {
                    throw new Error("Failed to submit feedback");
                }

                setIsSubmitted(true);
                setTimeout(() => {
                    setIsSubmitted(false);
                    setFormData({
                        name: "",
                        email: "",
                        feedbackType: "",
                        message: "",
                    });
                }, 4000);
            } catch (error) {
                console.error("Error submitting feedback:", error);
                // Handle error (e.g., show error message to user)
            }
        }
    };

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} align="center">
                <Box sx={{ padding: 3 }}>
                    <Typography variant="h4" gutterBottom>
                        Feedback
                    </Typography>
                    <Box sx={{ padding: 3 }}>
                        <Typography variant="body1" gutterBottom>
                            We greatly appreciate your feedback! Please note
                            that we do not collect any personal information
                            beyond what you provide in this form, and we do not
                            share any information with third parties. Your
                            privacy is important to us.
                        </Typography>
                    </Box>
                    <StyledForm onSubmit={handleSubmit}>
                        <TextField
                            name="name"
                            label="Name"
                            value={formData.name}
                            onChange={handleChange}
                            error={Boolean(errors.name)}
                            helperText={errors.name}
                            required
                        />
                        <TextField
                            name="email"
                            label="Email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            error={Boolean(errors.email)}
                            helperText={errors.email}
                            required
                        />
                        <FormControl error={Boolean(errors.feedbackType)}>
                            <InputLabel>Feedback Type</InputLabel>
                            <Select
                                name="feedbackType"
                                value={formData.feedbackType}
                                onChange={handleChange}
                                required
                            >
                                <MenuItem value="feedback">Feedback</MenuItem>
                                <MenuItem value="general">General</MenuItem>
                            </Select>
                            {errors.feedbackType && (
                                <Typography color="error">
                                    {errors.feedbackType}
                                </Typography>
                            )}
                        </FormControl>
                        <TextField
                            name="message"
                            label="Message"
                            multiline
                            rows={5}
                            value={formData.message}
                            onChange={handleChange}
                            error={Boolean(errors.message)}
                            helperText={errors.message}
                            required
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Submit
                        </Button>
                    </StyledForm>
                    <Snackbar
                        open={isSubmitted}
                        message="Thank you for your feedback!"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}

export default Feedback;
