import React from "react";
import { Grid, Typography, Box } from "@mui/material";

function Features() {
  return (
    <Grid container spacing={8} justifyContent="center" alignItems="flex-start">
      <Grid item xs={12} align="center">
        <Typography variant="h3" className="FeaturesTitle">
          Key Features
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box textAlign="center" alignItems="flex-start">
          <Typography variant="h5" gutterBottom>
            Generative AI
          </Typography>
          <Typography variant="body1">
            Harness the power of advanced language models to generate unique and engaging assessments tailored to your learning objectives.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box textAlign="center" alignItems="flex-start">
          <Typography variant="h5" gutterBottom>
            Multi-Agent Approach
          </Typography>
          <Typography variant="body1">
            Benefit from a collaborative multi-agent system that ensures the creation of comprehensive and well-structured assessments.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box textAlign="center" alignItems="flex-start">
          <Typography variant="h5" gutterBottom>
            Alignment with Teaching Objectives
          </Typography>
          <Typography variant="body1">
            Seamlessly align your assessments with your specific teaching objectives, ensuring that the generated content is relevant and meaningful.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box textAlign="center" alignItems="flex-start">
          <Typography variant="h5" gutterBottom>
            Educational Best Practices
          </Typography>
          <Typography variant="body1">
            The application incorporates educational best practices to create assessments that effectively measure student learning and foster engagement.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box textAlign="center" alignItems="flex-start">
          <Typography variant="h5" gutterBottom>
            Streamlined Interface
          </Typography>
          <Typography variant="body1">
            Enjoy a user-friendly interface that simplifies the process of creating assessments, saving you time and effort.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box textAlign="center" alignItems="flex-start">
          <Typography variant="h5" gutterBottom>
            Enhance Learning Outcomes
          </Typography>
          <Typography variant="body1">
            Educators can create assessments that drive student achievement and improve overall learning outcomes.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} align="center">
        <Typography variant="h3" className="FunctionTitle">
          Functionalities
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box textAlign="center" alignItems="flex-start">
          <Typography variant="h5" gutterBottom>
            Effortless Assessment Creation
          </Typography>
          <Typography variant="body1">
            Create assessments in just three clicks, saving you valuable time and streamlining the process.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box textAlign="center" alignItems="flex-start">
          <Typography variant="h5" gutterBottom>
            Fully Customizable
          </Typography>
          <Typography variant="body1">
            Tailor your assessments to your specific needs with a wide range of customization options.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box textAlign="center" alignItems="flex-start">
          <Typography variant="h5" gutterBottom>
            Multi-Disciplinary Support
          </Typography>
          <Typography variant="body1">
            Create assessments for various disciplines, ensuring versatility and adaptability across different subjects.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box textAlign="center" alignItems="flex-start">
          <Typography variant="h5" gutterBottom>
            Formative and Summative Assessments
          </Typography>
          <Typography variant="body1">
            Design assessments for both formative and summative purposes, catering to different stages of the learning process.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box textAlign="center" alignItems="flex-start">
          <Typography variant="h5" gutterBottom>
            Learning Taxonomies Integration
          </Typography>
          <Typography variant="body1">
            Incorporate learning taxonomies into your assessments, ensuring alignment with educational frameworks and objectives.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box textAlign="center" alignItems="flex-start">
          <Typography variant="h5" gutterBottom>
            Generative AI Control
          </Typography>
          <Typography variant="body1">
            Choose to incorporate or mitigate the use of Generative AI in your assessments, giving you control over the level of automation.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box textAlign="center" alignItems="flex-start">
          <Typography variant="h5" gutterBottom>
            Alternative Assessment Generation
          </Typography>
          <Typography variant="body1">
            Generate alternative assessments based on existing or shared assessments, providing fresh perspectives and variations.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box textAlign="center" alignItems="flex-start">
          <Typography variant="h5" gutterBottom>
            Multiple Learning Outcomes
          </Typography>
          <Typography variant="body1">
            Style your assessments to target multiple learning outcomes, ensuring comprehensive evaluation of student knowledge and skills.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box textAlign="center" alignItems="flex-start">
          <Typography variant="h5" gutterBottom>
            Diverse Assessment Styles
          </Typography>
          <Typography variant="body1">
            Create a variety of assessment styles, including written, quizzes, oral, problem-based, and more, to cater to different learning preferences and objectives.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box textAlign="center" alignItems="flex-start">
          <Typography variant="h5" gutterBottom>
            MCQs and Short-Form Questions
          </Typography>
          <Typography variant="body1">
            Generate multiple-choice questions (MCQs) and short-form questions based on learning objectives, providing a balanced mix of assessment formats.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Features;