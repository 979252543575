import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  Box,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import DOMPurify from "dompurify";
import LayersIcon from "@mui/icons-material/Layers";
import "../App.css";
import config from "../config";
import { marked } from "marked";

function AssessmentList() {
  const [assessments, setAssessments] = useState([]);
  const [mcqs, setMcqs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const assessmentsResponse = await fetch(
          `${config.webUrl}/assessments/`,
        );
        const assessmentsData = await assessmentsResponse.json();
        setAssessments(assessmentsData);

        const mcqsResponse = await fetch(`${config.webUrl}/mcq-shared-list/`);
        const mcqsData = await mcqsResponse.json();
        setMcqs(mcqsData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderAssessmentCards = (items, type) => (
    <Grid container spacing={2} className="MainCardGrid">
      {Array.isArray(items) &&
        items.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              onClick={() =>
                navigate(
                  type === "assessment"
                    ? `/assessments/list/${item.id}`
                    : `/mcqshared/${item.id}`,
                )
              }
              className="onecard"
            >
              <CardContent>
                <Box className="oneCardContent">
                  {type === "assessment" ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          marked(item.output.substring(0, 280) + "..."),
                        ),
                      }}
                    />
                  ) : (
                    <div>
                      <Typography variant="h6">
                        MCQ Set:{" "}
                        {item.title ||
                          (type === "assessment" ? "Assessment" : "MCQ Set")}
                      </Typography>
                      <Typography variant="body2" className="mcq_qtitle">
                        ({item.no_of_questions} Questions)
                      </Typography>
                      {item.questions.slice(0, 3).map((q, i) => (
                        <Typography key={i} variant="body2">
                          {i + 1}. {q.question.substring(0, 70)}...
                        </Typography>
                      ))}
                    </div>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
    </Grid>
  );

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Shared Assessments and MCQs
      </Typography>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Assessments" icon={<LayersIcon />} iconPosition="start" />
        <Tab label="MCQs" icon={<LayersIcon />} iconPosition="start" />
      </Tabs>
      {activeTab === 0 && renderAssessmentCards(assessments, "assessment")}
      {activeTab === 1 && renderAssessmentCards(mcqs, "mcq")}
    </div>
  );
}

export default AssessmentList;
