// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { ThemeContextProvider } from './components/ThemeContext';
import AssignmentGenerator from './components/AssignmentGenerator';
import AssessmentList from './components/AssessmentList';
import GeneratorSelector from './components/GeneratorSelector';
import SimpleAssessmentGenerator from './components/SimpleAssessmentGenerator';
import LandingPage from './components/LandingPage';
import Features from './components/Features';
import AssessmentDetail from './components/AssessmentDetail';
import { CssBaseline, Container, Box } from '@mui/material';
import MCQMaster from './components/MCQMaster';
import MCQDetail from './components/MCQDetail';
import Feedback from './components/Feedback';
import './App.css';

function App() {
  return (
    <ThemeContextProvider>
    <React.Fragment>
      <CssBaseline />
      <Router>
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Box component="main" flexGrow={1}>
        <Header />
        <Container maxWidth="lg">
          <Box sx={{ my: 4 }}>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/select" element={<GeneratorSelector />} />
              <Route path="/involved" element={<AssignmentGenerator />} />
              <Route path="/simple" element={<SimpleAssessmentGenerator />} />
              <Route path="/mcq" element={<MCQMaster />} />
              <Route path="/mcq/:id" element={<MCQDetail />} />
              <Route path="/mcqshared/:id" element={<MCQDetail />} />
              <Route path="/assessment/listall" element={<AssessmentList />} />
              <Route path="/assessments/list/:id" element={<AssessmentDetail />} />
              <Route path="/features" element={<Features />} />
              <Route path="/feedback" element={<Feedback />} />
            </Routes>
          </Box>
        </Container>
          </Box>
          <Footer />
        </Box>
      </Router>
    </React.Fragment>
    </ThemeContextProvider>
  );
}

export default App;